export const actionTypes = {
	// Utils
	USER_LOGOUT: 'USER_LOGOUT',
	SET_ACCESS_TOKEN: 'SET_ACCESS_TOKEN',
	SET_REFRESH_TOKEN: 'SET_REFRESH_TOKEN',
	SET_USER_ID: 'SET_USER_ID',
	SET_VERIFIED_PHONE_NO: 'SET_VERIFIED_PHONE_NO',
	SET_SELECTED_PLAN: 'SET_SELECTED_PLAN',
	SET_EMAIL: 'SET_EMAIL',
	SET_JWT_TOKEN: 'SET_JWT_TOKEN',


	// Auth
	LOGIN_REQUEST: 'LOGIN_REQUEST',
	LOGIN_SUCCESS: 'LOGIN_SUCCESS',
	LOGIN_FAILURE: 'LOGIN_FAILURE',
	LOGIN_RESET: 'LOGIN_RESET',
	LOGIN_RESET_ALL: 'LOGIN_RESET_ALL',

	// influencer
	INFLUENCER_LIST_REQUEST: 'INFLUENCER_LIST_REQUEST',
	INFLUENCER_LIST_SUCCESS: 'INFLUENCER_LIST_SUCCESS',
	INFLUENCER_LIST_FAILURE: 'INFLUENCER_LIST_FAILURE',
	INFLUENCER_LIST_RESET: 'INFLUENCER_LIST_RESET',
	INFLUENCER_LIST_RESET_ALL: 'INFLUENCER_LIST_RESET_ALL',


	APPROVED_REJECTED_INFLUENCER_REQUEST: 'APPROVED_REJECTED_INFLUENCER_REQUEST',
	APPROVED_REJECTED_INFLUENCER_SUCCESS: 'APPROVED_REJECTED_INFLUENCER_SUCCESS',
	APPROVED_REJECTED_INFLUENCER_FAILURE: 'APPROVED_REJECTED_INFLUENCER_FAILURE',
	APPROVED_REJECTED_INFLUENCER_RESET: 'APPROVED_REJECTED_INFLUENCER_RESET',
	APPROVED_REJECTED_INFLUENCER_RESET_ALL: 'APPROVED_REJECTED_INFLUENCER_RESET_ALL',




	// brand
	BRAND_LIST_REQUEST: 'BRAND_LIST_REQUEST',
	BRAND_LIST_SUCCESS: 'BRAND_LIST_SUCCESS',
	BRAND_LIST_FAILURE: 'BRAND_LIST_FAILURE',
	BRAND_LIST_RESET: 'BRAND_LIST_RESET',
	BRAND_LIST_RESET_ALL: 'BRAND_LIST_RESET_ALL',


	APPROVED_REJECTED_BRAND_REQUEST: 'APPROVED_REJECTED_BRAND_REQUEST',
	APPROVED_REJECTED_BRAND_SUCCESS: 'APPROVED_REJECTED_BRAND_SUCCESS',
	APPROVED_REJECTED_BRAND_FAILURE: 'APPROVED_REJECTED_BRAND_FAILURE',
	APPROVED_REJECTED_BRAND_RESET: 'APPROVED_REJECTED_BRAND_RESET',
	APPROVED_REJECTED_BRAND_RESET_ALL: 'APPROVED_REJECTED_BRAND_RESET_ALL',

}

